<!-- 我的通知-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-sousuo font22" ></i> -->
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>      
                        </div>                
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="doEdit()">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-edit font22" ></i> -->
                            <icon-svg icon-class="icon-chakan" font-size="27px;"/>
                            <span class="txt">{{msg('查看')}}</span>      
                        </div>         
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('notice:delete')">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-delete font22"></i> -->
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt">{{msg('删除')}}</span>      
                        </div>            
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="60px">
                <template v-slot:default="scope">
                    <el-form-item :label="msg('发布人')">
                        <el-input v-model="scope.query.fullname"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('标题')">
                        <el-input v-model="scope.query.title"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('概要')">
                        <el-input v-model="scope.query.overview"></el-input>
                    </el-form-item>
                    <el-form-item :label="msg('日期')" label-width="100px">
                        <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
                            value-format="yyyy-MM-dd"
                            end-placeholder=""
                            size="small"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getNotices" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" 
                resizable border
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="notices"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>

        <el-dialog :title="dglogTitle" width="960px" :visible.sync="dialogEditVisible"  :fullscreen="isMinScreen" @open="openView" :close-on-click-modal="false">
            <notice-view @close-dg="closeViewDialog" ref="noticeViewRef"/>
        </el-dialog>

    </div>
   
</template>

<script>

import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
import NoticeView from "./NoticeView.vue";
export default {
    name: 'Permission',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
        NoticeView: NoticeView,
    },
    mounted(){
        let curr = new Date();
        let prevTime =  curr.getTime() - (24 * 3600 * 1000);
        let prev= new Date(prevTime);
        this.query.Date = [this.$kit.fmtDate(prev), this.$kit.fmtDate(curr)]
        this.getNotices();
        this.initColumns();
    },
    data(){
        return {
            query:{
                title: null,
                overview: null,
                fullname:null,
                Date: [], 
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },

            showQuery: false,
            notices: [],      //通知列表
            columns:[],
           
            dgTitle: '',
            curr: null,
            dglogTitle:"查看",
            currNotice:null,
            currContent:null,
            dialogEditVisible:false,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        initColumns(){
            this.columns = [
                { type: 'checkbox', minWidth: 60 },
                {field: "id", title: this.msg("ID"), minWidth: 80, sortable: true},
                {field: "title", title: this.msg("标题"), minWidth: 200, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: "overview", title: this.msg("内容概要"), minWidth: 200, sortable: true },
                {field: "type", title: this.msg("通知类型"), minWidth: 200, sortable: true ,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: "fullname", title: this.msg("发布人"), minWidth: 120, sortable: true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'}
                },
                {field: "created", title: this.msg("发布时间"), minWidth: 120, sortable: true,
                    filters:[{data: []}],
                    filterRender:{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}
                },
            ]
        },
        getNotices(refresh){
            var _this = this;
            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);
            this.$axios.post(this.$kit.api.notice.list, params)
                .then((result) => {
                    if(result.status){
                        _this.notices = result.data.list;
                        _this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.$kit.clearQuery(this.query);
        },
        //点击行，选中行
        // checkRow({row, $event}){
        //     //如果点击checkbox 则不处理
        //     if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
        //         var table =  this.$refs.dataTable;
        //         table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        //     }
        //     if(columnIndex > 0){  //选择 
        //         var table =  this.$refs.dataTable;
        //         table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
        //     }
        // },
        //点击行，选中行
        checkRow({row, $event, column, columnIndex}){
            //如果点击checkbox 则不处理
            // console.log(columnIndex);
            // let className = $event.target.className;
           
            if(columnIndex > 0){  //选择 
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getNotices();
        },
        doEdit(){

            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            if(selectes.length > 1){
                this.$message(this.msg('只能打开一条数据'));
                return;
            }

            _this.currNotice = selectes[0];
            _this.dglogTitle = this.msg("查看通知");
            _this.dialogEditVisible = true;

        },
        closeViewDialog(type){  //编辑弹窗关闭后的回调事件
            this.dialogEditVisible = false;
        },
        doDelete(){
            var _this = this;
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }
            this.$confirm(this.msg('确定要删除选中的通知吗?'), this.msg('提示'), {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                _this.$axios.post(_this.$kit.api.notice.delete+"?ids="+selectes.map(e => e.id).join(","))
                .then((result) => {
                    _this.$message(result.msg || _this.msg('删除成功'));
                    _this.getNotices(true);
                });
            }).catch((err) => {
                // console.log(err);
            })
        },
        openView(){
            this.$nextTick(() => {
                this.$refs.noticeViewRef.init(this.currNotice.id);
            });
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
